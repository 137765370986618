.ant-modal-wrap.zero-padding-wrapper-modal {
  display: flex;
  justify-content: center;
  overflow: hidden !important;
  padding: 8px 0px;
  .ant-modal-modal-generic {
    display: flex;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    margin: 0px !important;
    .ant-modal-content {
      margin-top: auto;
      margin-bottom: auto;
      max-height: 100%;
      display: flex;
      flex: 1 1 auto;
      overflow: hidden;
      .ant-modal-body {
        padding: 0px;
        max-height: 100%;
        width: 100%;
        .ant-modal-modal-generic-body-wrapper {
          height: 100%;
          .ant-modal-modal-generic-body {
            height: 100%;
          }
        }
      }
    }
  }
}

.wrapper-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow: hidden;
  height: 100%;
  width: 100%;
  .wrapper-header {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title-text {
      font-weight: 600;
    }
    .close-button {
      color: white;
      cursor: pointer;
      &:hover {
        color: #242424;
      }
    }
  }
  .wrapper-body {
    padding: 14px;
    overflow-y: auto;
  }
  .wrapper-footer {
    border-top: 1px solid #e6e6e6;
    padding: 10px 16px;
  }
}

@primary-color: #0f9d58;@border-color-base: #d9d9d9;@background-color: #2b8a2a;@btn-disable-bg: #00000040;@link-color: #40a86c;@success-color: #52c41a;@warning-color: #fa5d14;@error-color: #d6222b;@font-size-base: 12px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.7);@text-color-secondary: rgba(0, 0, 0, 0.55);@disabled-color: rgba(0, 0, 0, 0.5);@border-radius-base: 2px;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@layout-footer-padding: 14px 14px;